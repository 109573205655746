import React, { useCallback } from 'react';

import { Button, Loader, toast } from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

import BackButton from 'src/Components/BackButton';
import InputLabel from 'src/Components/InputLabel';
import { useEditAgencyCalendar } from 'src/Hooks/CustomRandy/useEditAgencyCalendar';
import { useGetAgencyCalendar } from 'src/Hooks/CustomRandy/useGetAgencyCalendar';
import { useRemoveAgencyCalendar } from 'src/Hooks/CustomRandy/useRemoveAgencyCalendar';
import history from 'src/Utils/history';

import {
  AGENDA_HELP_LINK,
  CustomRandySection,
  CustomRandySubSection,
  URL,
} from '../../CustomRandy.types';

import Styles from './AgencyEditCalendarScreen.module.scss';

const AgencyEditCalendarScreen = () => {
  const queryClient = useQueryClient();
  const { agencyId, calendarId } = useParams<{
    agencyId: string;
    calendarId: string;
  }>();
  const { data } = useGetAgencyCalendar(agencyId, calendarId);

  const { mutate: editAgencyCalendar, isLoading } = useEditAgencyCalendar({
    onSuccess: async (_, { body }) => {
      reset(body);
      toast.success('configuration enregistrée');
      await queryClient.invalidateQueries(['agency-calendars', agencyId]);
    },
    onError: err => {
      switch (err.cause) {
        case 'PRIVILEGES':
          toast.error("le compte de service n'a pas de droits en écriture sur ce calendrier");
          break;
        case 'BAD_EMAIL':
          toast.error('compte de service inconnu');
          break;
        case 'BAD_CALENDAR':
          toast.error('identifiant de calendrier inconnu');
          break;
        case 'UNKNOWN':
        default:
          toast.error(`erreur lors de l'enregistrement: ${err.message}`);
      }
    },
  });
  const { mutate: removeAgencyCalendar, isLoading: isRemoving } = useRemoveAgencyCalendar({
    onSuccess: async () => {
      await queryClient.invalidateQueries(['agency-calendars', agencyId]);
      history.push(
        `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}`
      );
      toast.success('calendrier supprimé');
    },
    onError: () => {
      toast.error('erreur lors de la suppression');
    },
  });

  const schema = z.object({
    title: z.string().min(1),
    calendarIdentification: z
      .string()
      .regex(/^c_[0-9a-f]{64}@group\.calendar\.google\.com$/, 'identifiant invalide'),
    delegatedEmail: z
      .string()
      .email('adresse email invalide')
      .regex(/@randstad\.fr$/, 'le compte de service doit avoir une adresse @randstad.fr'),
  });

  const { control, handleSubmit, formState, reset } = useFormWithZodResolver({
    schema,
  });

  const submit = useCallback(
    (values: z.infer<typeof schema>) => {
      editAgencyCalendar({ agencyId, calendarObjectId: calendarId, body: values });
    },
    [editAgencyCalendar, agencyId, calendarId]
  );

  return (
    <form className={Styles.AgencyEditCalendarScreen} onSubmit={handleSubmit(submit)}>
      <BackButton
        text="retour"
        onClick={() =>
          history.push(
            `/${URL}/${CustomRandySection.links}/${CustomRandySubSection.agency}/${agencyId}`
          )
        }
      />
      <div className={Styles.TitleContainer}>
        <div>
          <h2 className={Styles.Title}>agence {agencyId}</h2>
          <p className={Styles.Paragraph}>{data?.title}</p>
        </div>
        {data && (
          <Button.Secondary
            text="supprimer"
            type="button"
            disabled={isRemoving}
            rightIcon={<Trashcan />}
            onClick={() => removeAgencyCalendar({ agencyId, calendarObjectId: calendarId })}
          />
        )}
      </div>

      <a href={AGENDA_HELP_LINK} target="_blank" className={Styles.Link} rel="noreferrer">
        guide de paramétrage de l'agenda
      </a>

      {!data ? (
        <Loader heightInRem={2} darkMode />
      ) : (
        <>
          <InputLabel label="nom du calendrier">
            <TextInput
              placeholder="ex: recrutement collectif techniciens"
              name="title"
              control={control}
              defaultValue={data.title}
            />
          </InputLabel>

          <InputLabel
            label="identifiant de calendrier"
            explanation="se référer à la partie 4 du guide de paramétrage"
          >
            <TextInput
              placeholder="ex: c_48a271e49bdf650572549f4e263c009cabc0aac7567@group.calendar.google.com"
              name="calendarIdentification"
              control={control}
              defaultValue={data.calendarIdentification}
            />
          </InputLabel>

          <InputLabel
            label="compte de service"
            explanation="se référer à la partie 3 du guide de paramétrage"
          >
            <TextInput
              placeholder="ex: adresse.email@randstad.fr"
              name="delegatedEmail"
              control={control}
              defaultValue={data.delegatedEmail}
            />
          </InputLabel>
        </>
      )}

      <Button
        type="submit"
        text="enregistrer"
        disabled={isLoading || !formState.isDirty || !formState.isValid}
        rightIcon={isLoading ? <Loader heightInRem={2} /> : undefined}
      />
    </form>
  );
};

export default AgencyEditCalendarScreen;
